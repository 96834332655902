import React, { useContext} from 'react'
import { GlobalDataContext } from '../../context/context'

import {ButtonContent} from '../global/boton/ButtonContent'

import { HiChevronRight } from 'react-icons/hi'
import { BiChevronRightSquare } from 'react-icons/bi'

const BlockContent = ({ title, sloganPrincipal, listServices, listAbout, texts, images }) => {

  const { rpdata } = useContext(GlobalDataContext)



  return (
    <section className='flex flex-col-reverse md:flex-row items-center mb-20 md:mb-0'>

      <div className='w-full md:w-1/2 pt-10 md:pt-0'>
        <div className='w-4/5 mt-0 md:my-20 mx-auto md:ml-28 md:mr-20 flex flex-col gap-10 text-center md:text-start'>
          {
            sloganPrincipal ?
              rpdata?.dbPrincipal?.licensed ?
                <h1 className='text-[35px] md:text-[60px]'>{rpdata?.dbPrincipal?.licensed}</h1>
                :
                <h1 className='text-[35px] md:text-[60px]'>
                  {
                    rpdata?.dbPrincipal?.exprYears ?
                      `we have ${rpdata?.dbPrincipal?.exprYears} years of experience`
                      : 'we have many years of experience'
                  }
                </h1>
              :
              <h1 className='text-[35px] md:text-[60px]'>{title}</h1>
          }
          <p>{texts}</p>

          {
            listServices ?
              <ul className="grid grid-cols-1 md:grid-cols-2 gap-5 pb-5">
                {rpdata?.dbServices?.slice(0, 8).map((item, index) => {
                  return (
                    <li key={index} className="flex items-center gap-3">
                      <BiChevronRightSquare className='titleColorIcons' />
                      <span>{item.name}</span>
                    </li>
                  )
                })}
              </ul>
              : null
          }

          {
            listAbout ?
              <ul className='grid grid-cols-1 md:grid-cols-2 gap-5 pb-5'>
                {
                  rpdata?.dbAbout?.[0].list.length > 1 ?
                    rpdata?.dbAbout?.[0].list.map((item, index) => {
                      return (

                        <li key={index} className="flex items-center gap-1">
                          <HiChevronRight  className='titleColorIcons'/>
                          <span>{item}</span>
                        </li>
                      )
                    })
                    : null
                }
              </ul>
              : null
          }

          <div>
            <ButtonContent btnStyle={'three'} />
          </div>
        </div>
      </div>
      <div className='w-[90%] md:w-1/2 h-[350px] md:h-[80vh] ml-auto md:ml-20'>
        <picture>
          <img
            src={images}
            alt={'images'}
            className='svgParrallelogra w-full h-full object-cover'
          />
        </picture>
      </div>

    </section>
  )
}

export default BlockContent
import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context'
import { Link } from 'react-router-dom';

import IconsRedes from "../IconRedes";

import { BsPinMap, BsEnvelope, BsTelephone } from 'react-icons/bs'
import { GoChevronRight } from 'react-icons/go'
import { TbCalendarTime } from 'react-icons/tb'
import { MdOutlineAttachMoney } from 'react-icons/md'


const Footers = () => {

    const { rpdata } = useContext(GlobalDataContext)

    // date
    const yearsActual = new Date();

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const linksMenu = [
        {
            label: 'Home',
            route: '/',
            child: false,
        },
        {
            label: 'About',
            route: '/about',
            child: false,
        },
        {
            label: 'Services',
            route: '/services',
            child: rpdata?.autoGntLandingFromService,
        },
        {
            label: 'Gallery',
            route: '/gallery',
            child: rpdata?.customLinks,
        },
        {
            label: 'Contact',
            route: '/contact',
            child: false,
        }
    ]

    // agregar la pestaña de Video al array de lianksMenu
    const yt = {
        label: `Our Videos`,
        route: `/our-videos`,
        child: false,
    }

    if (rpdata?.ytGallery?.linkCanalYT) {
        const num = linksMenu.length - 1
        linksMenu.splice(num, 0, yt)
    }
    //  fin de agregar pestaña de Video

    // agregar la pestaña de reviews al array de linksMenu

    const el = {
        label: `Reviews`,
        route: `/reviews`,
        child: false,
    }

    if (rpdata?.reviews?.activo && rpdata?.reviews?.isHomeOnly === false) {
        const num = linksMenu.length - 1
        linksMenu.splice(num, 0, el)
    }
    //  fin de agregar pestaña de reviews


    return (
        <footer className='h-full bgOverlayColor svgTriangulotop pt-7'>
            <section className='w-[98%] mx-auto bg-footer h-full svgTriangulotop'>
                <div className='w-4/5 h-full mx-auto flex flex-col justify-center pt-28 pb-5'>

                    <div className='flex flex-col items-center justify-center mt-20 mb-10 md:mt-0 md:mb-20'>
                        <picture className="flex justify-center items-center">
                            <img
                                src={rpdata?.dbPrincipal?.logo}
                                alt='logo'
                                className="w-full md:w-[45%]"
                            />
                        </picture>
                        <div className="text-white text-center p-5 block md:hidden">
                            <p>{rpdata?.dbAbout?.[1]?.text}</p>
                        </div>
                    </div>

                    <div className='flex flex-col md:flex-row border-b-0 md:border-b mb-0 md:mb-10'>
                        {
                            rpdata?.simpleWidgets?.[3]?.activo ?
                                null
                                :
                                <div className="pb-10 w-full md:w-[20%]">
                                    <h3 className='titleColorFt font-bold text-[30px] pb-10'>Navigation</h3>
                                    <div className='pr-0 md:pr-20'>
                                        <ul className="pl-2">
                                            {
                                                linksMenu.map((item, index) => {
                                                    return (
                                                        <li className="py-2" onClick={goToTop} key={index}>
                                                            <Link to={item.route} className="flex text-white linksColorFtHover">
                                                                <GoChevronRight className="self-center" />
                                                                <span className="pl-2">{item.label}</span>
                                                            </Link>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                        }

                        <div className="pr-0 md:pr-20 pb-10 w-full md:w-[60%] ">
                            <h3 className='titleColorFt font-bold text-[30px] pb-10 text-center'>services</h3>
                            <ul className="grid grid-cols-1 md:grid-cols-2 gap-5 text-[#EBEBEB]">
                                {
                                    rpdata?.dbServices?.slice(0, 8)?.map((item, index) => {
                                        return (
                                            <li key={index} className='font-semibold text-[18px]'>
                                                {
                                                    rpdata?.simpleWidgets?.[3]?.activo ?
                                                        <a href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`} className='flex items-center gap-2 asColorFtHover'>
                                                            <GoChevronRight />
                                                            {item?.name}
                                                        </a>
                                                        :
                                                        <Link to={`/services`} className='flex items-center gap-2 linksColorFtHover'>
                                                            <GoChevronRight />
                                                            {item?.name}
                                                        </Link>
                                                }

                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>

                        <div className={`
                        pb-10 w-full 
                        ${rpdata?.simpleWidgets?.[3]?.activo ? 'md:w-[40%] pl-0 md:pl-[100px]' : 'md:w-[30%]'}
                        `}>
                            <h3 className='titleColorFt font-bold text-[30px] pb-10'>contact</h3>

                            <div className="text-[#EBEBEB] text-[18px]">

                                {/* direccion */}
                                <div className="w-full flex items-center gap-3 pb-5">
                                    <BsPinMap />
                                    <span>{rpdata?.dbPrincipal?.location?.[0]?.address}</span>
                                </div>

                                {/* emails */}
                                <ul className="w-full">
                                    {
                                        rpdata?.dbPrincipal?.emails?.map((email, index) => {
                                            return (
                                                <li key={index} className='flex items-center gap-3 pb-5'>
                                                    <a
                                                        href={`mailto:${email.email}`}
                                                        className='flex items-center gap-3 ease-in-out duration-500 linksColorFtHover'
                                                    >
                                                        <BsEnvelope />
                                                        <span>{email.email}</span>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>

                                {/* Phones */}
                                <ul className="w-full">
                                    {
                                        rpdata?.dbPrincipal?.phones?.map((phone, index) => {
                                            return (
                                                <li key={index}>
                                                    <a
                                                        href={`tel:+1${phone.phone}`}
                                                        className='flex items-center gap-3 pb-5 ease-in-out duration-500 linksColorFtHover'
                                                    >
                                                        <BsTelephone />
                                                        <span>{phone.phone}</span>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>

                                {/* metodo de pago */}
                                <div className="flex flex-col">
                                    <div className="flex items-center gap-3">
                                        <MdOutlineAttachMoney />
                                        <span>{rpdata?.dbPrincipal?.paymentMethod}</span>
                                    </div>
                                    <div>
                                        {
                                            rpdata?.tiposPago?.[0]?.activo ?
                                                <picture>
                                                    <img
                                                        src={rpdata?.tiposPago?.[0]?.img}
                                                        alt='payment method'
                                                        className="w-[70%] mx-auto"
                                                    />
                                                </picture>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="flex fle-col md:flex-row">
                        <div className="text-white w-full md:w-[70%] hidden md:block">
                            <p className="pb-10">{rpdata?.dbAbout?.[1]?.text}</p>
                            {/* redes */}
                            <IconsRedes classes={'flex justify-center md:justify-start items-center gap-5'} />
                        </div>

                        <div className="w-full md:w-[30%] border-l-0 border-b md:border-b-0 md:border-l pb-5 md:pb-0 ml-0 pl-0 md:ml-5 md:pl-10">
                            <h3 className='titleColorFt font-bold text-[30px] md:text-[20px] pb-5'>opening hours</h3>

                            <ul className="text-white">
                                {
                                    rpdata?.dbPrincipal?.workdays?.map((days, index) => {
                                        return (
                                            <li key={index}>
                                                <div className='flex items-center gap-3'>
                                                    <TbCalendarTime />
                                                    <span>{days.day}</span>
                                                </div>
                                                <p className="pl-7">
                                                    {rpdata?.dbPrincipal?.workHours[index].hour}
                                                </p>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>

                    <div className="pt-10">
                        <div className="block md:hidden">
                            <IconsRedes classes={'flex justify-center items-center gap-5'} />
                        </div>

                        <p className='py-5 text-white text-center'>
                            ©{yearsActual.getFullYear()}, Copyright By {rpdata?.dbPrincipal?.name} All Rights Reserved
                        </p>
                    </div>

                </div>
            </section>

        </footer>
    )
}

export default Footers